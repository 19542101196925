import { n as normalizeComponent } from "./vueComponentNormalizer.js";
import { GROWERS_OPERATORS } from "@kraftheinz/common";
var render$1 = function() {
  var _vm = this;
  var _h = _vm.$createElement;
  var _c = _vm._self._c || _h;
  return _c("edit-modal-composer", {
    attrs: {
      "title": "Edit Operator",
      "key-receive-event": ["windowsUserID"]
    },
    on: {
      "change": _vm.changeControl
    }
  }, [_c("text-input", {
    key: "id",
    attrs: {
      "disabled": true,
      "label": "Operator Code",
      "span": 12
    }
  }), _c("text-input", {
    key: "operatorName",
    attrs: {
      "label": "Operator Name",
      "span": 12,
      "rules": "required|max:30"
    }
  }), _c("auto-complete-input", {
    key: "windowsUserID",
    attrs: {
      "label": "Windows UserID",
      "span": 12,
      "reference": "identities.ad.users",
      "source": "samAccountName",
      "source-label": "samAccountName",
      "rules": "required",
      "custom-query": _vm.customQuery,
      "only-custom-query": true
    }
  }), _c("select-input", {
    key: "facility",
    attrs: {
      "reference": "grower.common.facilities",
      "source": "code",
      "source-label": "name",
      "label": "Facility",
      "span": 12
    }
  }), _c("select-input", {
    key: "warehouseId",
    attrs: {
      "label": "Warehouse",
      "reference": "grower.common.ware-houses",
      "source": "id",
      "source-label": "code",
      "span": 12
    }
  })], 1);
};
var staticRenderFns$1 = [];
const __vue2_script$1 = {
  name: "EditOperator",
  inject: ["crud"],
  data() {
    return {
      customQuery: ""
    };
  },
  computed: {
    entity() {
      return this.crud.getEntity() || {};
    }
  },
  async created() {
    await this.crud.fetchEntity();
    this.changeControl({ windowsUserID: this.entity.windowsUserID });
  },
  methods: {
    changeControl(e) {
      if (e.windowsUserID)
        this.customQuery = "keyword=" + e.windowsUserID;
      else
        this.customQuery = "";
    }
  }
};
const __cssModules$1 = {};
var __component__$1 = /* @__PURE__ */ normalizeComponent(__vue2_script$1, render$1, staticRenderFns$1, false, __vue2_injectStyles$1, null, null, null);
function __vue2_injectStyles$1(context) {
  for (let o in __cssModules$1) {
    this[o] = __cssModules$1[o];
  }
}
var EditOperator = /* @__PURE__ */ function() {
  return __component__$1.exports;
}();
var render = function() {
  var _vm = this;
  var _h = _vm.$createElement;
  var _c = _vm._self._c || _h;
  return _c("div", [_c("resource", {
    attrs: {
      "name": "identities.ad.users",
      "api-url": _vm.apiUrl
    }
  }), _c("resource", {
    attrs: {
      "name": "grower.operators",
      "redirect-route": "/watties-grower/operators",
      "api-url": _vm.apiUrl,
      "page": _vm.page
    }
  }, [_c("edit-operator")], 1)], 1);
};
var staticRenderFns = [];
const apiUrl = "#{VUE_APP_API_URL}#";
const __vue2_script = {
  components: {
    EditOperator
  },
  data() {
    return {
      apiUrl,
      page: GROWERS_OPERATORS
    };
  }
};
const __cssModules = {};
var __component__ = /* @__PURE__ */ normalizeComponent(__vue2_script, render, staticRenderFns, false, __vue2_injectStyles, null, null, null);
function __vue2_injectStyles(context) {
  for (let o in __cssModules) {
    this[o] = __cssModules[o];
  }
}
var index = /* @__PURE__ */ function() {
  return __component__.exports;
}();
export { index as default };
